<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Jom Bantu
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-upload',
          path: '/operation/jb_update_details',
          title: 'Upload Details',
          desc: 'Upload Details from school',
        },
        {
          icon: 'mdi-format-list-numbered',
          path: '/operation/jb_list_student',
          title: 'Student Listing',
          desc: 'Student list',
        },
      ]

      return menu
    },
  },
}
</script>
